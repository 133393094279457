import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { styled } from '@mui/material/styles';
import videojs from "video.js";
import { useEventListener } from '../hooks/hooks'
import { IconButton } from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOffOutlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUpOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!style-loader!css-loader!video.js/dist/video-js.css'
import {
  registerIVSQualityPlugin,
  registerIVSTech,
  MetadataEventType,
} from 'amazon-ivs-player';

import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';

const PREFIX = 'TicktBoxVideoPlayer';


const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  continue: `${PREFIX}-continue`,
  continueBack: `${PREFIX}-continueBack`,
  margin: `${PREFIX}-margin`,
  resume: `${PREFIX}-resume`,
  youtube: `${PREFIX}-youtube`,
  player: `${PREFIX}-player`,
  playButton: `${PREFIX}-playButton`,
  muteButton: `${PREFIX}-muteButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 2rem 2rem'
  },
  [`& .${classes.continue}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 1rem 2rem',
    zIndex: 1002,
    width: '35%',
    minWidth: '160px',
    maxWidth: '260px'
  },
  [`& .${classes.continueBack}`]: {
    zIndex: 1001,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(12px)'
  },
  [`& .${classes.margin}`]: {
    margin: '1rem'
  },
  [`& .${classes.resume}`]: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    // backgroundColor: '#151515'
  },
  [`& .${classes.youtube}`]: {
    maxWidth: props => props.aspectRatio ? `${1 / props.aspectRatio * 100}vh` : 0,
    maxHeight: props => props.aspectRatio ? `${props.aspectRatio * 100}vw` : 0,
    width: props => props.aspectRatio ? '100%' : 0,
    height: props => props.aspectRatio ? '100%' : 0,
    flex: 1
  },
  [`& .${classes.player}`]: {
    width: '100%',
    height: '100%',
    pointerEvents: 'none'
  },
  [`& .${classes.player} iframe`]: {
    width: '100%',
    height: '100%',
  },
  [`& .${classes.playButton}`]: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: 'white',
    zIndex: 1004
  },
  [`& .${classes.muteButton}`]: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: 'white',
    zIndex: 1200
  },
});

const createAbsolutePath = (assetPath) => new URL(assetPath, document.URL).toString();

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const overlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width || 200} ${height || 100}'>
    <svg height='100%' width='100%' viewBox='0 0 200 100'>    
      <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
              <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
              <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
              <feGaussianBlur in="map" stdDeviation="${Math.floor(Math.random()) / 1000 + 0.15}" />
      </filter>
      <text x="50%" y="50%" transform="rotate(-${isNaN(height / width) ? 20.5 : Math.atan(height / width) * 180 / Math.PI}, 100, 50)" filter="url(#filter)" textLength="200" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
    </svg>
  </svg>`
}

const tiledOverlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%'>
      <defs>
        <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
          <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
          <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
          <feGaussianBlur in="map" stdDeviation="${height / 2000} ${width / 2000}" />
        </filter>
        <pattern id="p1" height='25%' width='50%' style="font-size: ${height / 22}px" patternUnits="userSpaceOnUse" patternTransform="rotate(-18.43494882292201)">    
          <text x="25%" y="12.5%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
        </pattern>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#p1)" filter="url(#filter)"></rect>
    </svg>
  </svg>`
}

const IVSVideoPlayer = React.memo(({ videoRef, style, fluid, fill, fullscreen, onTimeUpdate, videoStreamData, sessionStartTime, wm, watermarkStyle, videoStopTime, videoDuration, onBuffer, startVideo, alreadyStarted, onStarted, onMetadataLoaded, onVideoLoaded, onPlay, onVisiblityChange, onFinished, onVideoError, isLive, liveStreamStatus, sessionComplete }) => {
  const [muted, setMuted] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [playerLoaded, setPlayerLoaded] = useState(false)

  const visibilityChangeFn = () => { }

  const playerRef = useRef(null);
  const allowPause = useRef(false)
  const videoNodeRef = useRef();
  const syncRef = useRef(0);

  const overlayFn = useMemo(() => {
    return watermarkStyle === 'Tiled' ? tiledOverlay : overlay
  }, [watermarkStyle])


  useEffect(() => {
    if (startVideo && playerRef.current) playerRef.current.play()
  }, [startVideo])

  useEffect(() => {
    if ((document[hidden] || !document.hasFocus()) && onVisiblityChange) onVisiblityChange()
  }, [onVisiblityChange])

  useEffect(() => {
    console.log('VIDEO - IS SESSION COMPLETE?', sessionComplete)
    if (sessionComplete && playerRef.current) playerRef.current.pause()
  }, [sessionComplete])

  useEventListener('blur', onVisiblityChange || visibilityChangeFn, true);
  useEventListener('focus', onVisiblityChange || visibilityChangeFn, true);
  useEventListener(visibilityChange, onVisiblityChange || visibilityChangeFn, true);

  useEffect(() => {
    const now = Date.now()
    if (sessionComplete || (sessionStartTime && (now - sessionStartTime) >= ((videoStopTime * 1000) || videoDuration))) {
      if (onFinished) {
        onFinished()
      } else if (playerRef.current) {
        playerRef.current.currentTime(playerRef.current.duration())
      }
    }
  }, [sessionComplete, videoDuration, videoStopTime, sessionStartTime, onFinished])

  useEffect(() => {
    registerIVSTech(videojs, {
      wasmWorker: createAbsolutePath(wasmWorkerPath),
      wasmBinary: createAbsolutePath(wasmBinaryPath),
    });
    registerIVSQualityPlugin(videojs);

    playerRef.current = videojs(videoNodeRef.current, {
      techOrder: ["AmazonIVS"],
      // controls: true,
      autoplay: true
    }, () => {
      playerRef.current.currentStreamTime = () => {
        return syncRef.current + playerRef.current.currentTime() // stream offset
        // return syncRef.current + (playerRef.current.currentTime() * 1000) // timestamp
      }
      const events = playerRef.current.getIVSEvents();
      const ivsPlayer = playerRef.current.getIVSPlayer();
      playerRef.current.on('play', (event) => {
        console.log('play event')
        if (onStarted) onStarted()
      })
      ivsPlayer.addEventListener(events.PlayerState.ENDED, () => {
        console.log('IVS Player has ended')
        if (onFinished) onFinished()
        console.log('playerRef.current:', playerRef.current)
        playerRef.current.ended();

      });
      ivsPlayer.addEventListener(events.PlayerState.PLAYING, () => {
        console.log('IVS Player is playing')
        if (onStarted) onStarted()
      });
      ivsPlayer.addEventListener(events.PlayerState.READY, () => {
        console.log('IVS Player is ready')
      });
      ivsPlayer.addEventListener(events.PlayerState.IDLE, () => {
        console.log('IVS Player is paused/idle')
        if (!(playerRef.current.ended() || allowPause.current)) {
          playerRef.current.play()
        } else {
          allowPause.current = false
          playerRef.current.currentTime(0)
        }
      });
      ivsPlayer.addEventListener(events.PlayerEventType.SYNC_TIME_UPDATE, (e) => {
        // console.log('IVS Player TIME_UPDATE', e)
        playerRef.current.overlay({
          content: '',
          class: classes.customOverlay,
          overlays: [{
            align: "center",
            content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
            start: 'play',
            end: 'ended'
          }]
        });
        if (onTimeUpdate) onTimeUpdate({
          target: { currentTime: playerRef.current.currentStreamTime() }
        });
      });
      ivsPlayer.addEventListener(MetadataEventType.ID3, (event) => {
        if (!syncRef.current) { // TO DO: HANDLE START FROM BEGINNING
          const segmentMetadata = event.find(tag => tag.desc === 'segmentmetadata');
          const segmentInfo = JSON.parse(segmentMetadata.info[0]);
          console.log('segmentInfo:', segmentInfo)
          syncRef.current = segmentInfo['stream_offset'] //segmentInfo['transc_s'];
        }
      });
    });

    playerRef.current.enableIVSQualityPlugin();
    setPlayerLoaded(true)

    // return () => {
    //   // Clean up player on unmount
    //   if (playerRef.current) {
    //     ivsPlayer.removeEventListener(events.PlayerState.ENDED, () => { });
    //     ivsPlayer.removeEventListener(events.PlayerState.PLAYING, () => { });
    //     ivsPlayer.removeEventListener(events.PlayerState.IDLE, () => { });
    //     playerRef.current.dispose();
    //   }
    // };
  }, [fill, fluid, onFinished, onStarted, onPlay, onBuffer, onMetadataLoaded, onTimeUpdate, onVideoError, onVideoLoaded, classes.customOverlay, sessionStartTime, wm, overlayFn])

  const afterLoaded = useCallback((videoUrl) => {
    playerRef.current.src(videoUrl)
    // playerRef.current.load()
    if (videoRef) videoRef.current = playerRef.current //to get time data

    playerRef.current.controls(false);
    if (fullscreen) playerRef.current.enterFullWindow();
    playerRef.current.autoplay(false)
    playerRef.current.muted(true)

    //INITAL BLANK OVERLAY
    playerRef.current.overlay({
      content: '',
      class: classes.customOverlay,
      overlays: [{
        align: "center",
        content: "",
        start: 'play',
        end: 'ended'
      }]
    });

    // GUEST PLAYER
    // if (!alreadyStarted) playerRef.current.play()

    // ADMIN VIEWER
    if (alreadyStarted && !(sessionComplete || ((sessionStartTime && (Date.now() - sessionStartTime) / 1000) >= videoDuration))) {
      playerRef.current.currentTime((Date.now() - sessionStartTime) / 1000)
      playerRef.current.play()
    }

  }, [alreadyStarted, sessionStartTime, sessionComplete, videoDuration, fullscreen, classes.customOverlay, videoRef])

  useEffect(() => {
    console.log('player loaded?', playerLoaded)
    if (playerLoaded && videoStreamData) {
      videoStreamData().then(({ url }) => {
        afterLoaded(url)
      }).catch((err) => {
        console.log('ERROR FETCHING STREAM DATA: ', err)
      })
    }
  }, [afterLoaded, videoStreamData, playerLoaded])

  const toggleMute = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      playerRef.current.muted(!muted)
      setMuted(prev => !prev)
    }
  }, [muted, setMuted])

  const togglePlay = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      if (playing) {
        allowPause.current = true
        // playerRef.current.currentTime(playerRef.current.duration())
        playerRef.current.pause()
      } else {
        playerRef.current.play()
      }
      // playing ? playerRef.current.currentTime(playerRef.current.duration()) : playerRef.current.play()
      setPlaying(prev => !prev)
    }
  }, [playing])

  const toggleFullScreen = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      playerRef.current.requestFullscreen();
    }
  }, [])

  return (
    // <div className="video-container">
    //   <video
    //     id="video-js-live"
    //     // ref={videoRef}
    //     className="video-js vjs-big-play-centered"
    //     autoPlay
    //   />
    // </div>
    <Root>
      <div style={{ position: 'relative' }}>
        <video ref={videoNodeRef} className='video-js'></video>
        {(!isLive && !startVideo && !alreadyStarted) ?
          <IconButton className={classes.playButton} color="inherit" onClick={togglePlay}>
            {playing ? <StopIcon /> : <PlayArrowIcon />}
          </IconButton> : <IconButton className={classes.playButton} color="inherit" onClick={toggleFullScreen}>
            {/* {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />} */}
            <FullscreenIcon />
          </IconButton>
        }
        <IconButton className={classes.muteButton} color="inherit" onClick={toggleMute}>
          {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </div>
    </Root>
  );
});

export default IVSVideoPlayer;
